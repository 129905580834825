import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Nav from './components/NavC';
import Footer from './components/FooterC';
import Home from './components/HomeC';
import Historia from './components/HistoriaC';
import Connect from './components/ConnectC';
import Receta from './components/RecetaC';
import Tags from './components/TagsC';



function App() {
  return (
   <Router>

     <Nav />

        <Switch>
            
            <Route path="/receta" component={Receta} /> 
            <Route path="/tags" component={Tags} />
            <Route path="/historia" exact component={Historia} />
            <Route path="/letsconnect" exact component={Connect} />
            
            <Route path="/" exact component={Home} />
            
        </Switch>


    <Footer />

   </Router>

  );
}

export default App;
