import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import recetas from './recetas.json'


class Footer extends Component {
    state = {  }
    render() { 
        let ultimas_recetas=[]
        let inc=0
         for (let i = 0; i < 5; i++) {
             inc=inc+1
            ultimas_recetas.push({titulo:recetas[i].titulo,url:recetas[i].titulo_url,id:recetas[i].id})
            
         
          }
       

          let tags_list=["chocolate","vainilla","rosca","fresa","pay","brownies","aguacate","mermelada","casera","platano","integral","sin-azucar","yogurt","gelatina","mousse","espinacas","nuez","pistaches","granola","nutella","garbanzo","pan","tradicional","calabaza","galletas","cheesecake","cupcakes","flan","napolitano","mexicano"]
        return ( 
            <div>
<div className="container-fluid bg-light ">
    <br></br>
</div>
            
            <div className="container-fluid row py-3">
       <div className="col-lg">
        <div className="row">



        <div className="col-sm py-4">
        <div className="d-flex flex-column justify-content-center">
            <p className=" fs-4 py-2 fw-bold m-0">TAGS</p>
            <div className="d-flex flex-wrap">

            {tags_list.map((tag, index)=>(
                 <Link className="link-clean" key={index} to={`/tags/${tag}`}><div className="border m-1 fs-6 p-1">{tag}</div></Link>
            ))}
           
            
            </div>
            
            
        </div>
        </div>

        </div>
    </div>
    
    <div className="col-lg">
        <div className="row">
            <div className="col-sm py-4">
        <div className="d-flex flex-column justify-content-center">
        <p className=" fs-4 py-3 m-0 fw-bold">RECETAS NUEVAS</p>
        <div>

 
{ultimas_recetas.map((ulti)=>(
  <Link className="link-clean" key={ulti.id} to={`/receta/${ulti.url}`}><div  className="border-top border-bottom fs-6  p-2">{ulti.titulo}</div></Link>

))}

        </div>
            
            
        </div>
        </div>


        <div className="col-sm py-4">
        <div className="d-flex flex-column justify-content-center">
            <p className=" fs-4 py-3 m-0 fw-bold">SIGUENOS</p>
            <div className="">
            <a href="https://www.youtube.com/channel/UCJ7S2DnLRiNj8Vg9XnAPIbA?view_as=subscriber" className="fa fa-youtube-play"> </a>
            <a href="https://www.facebook.com/delidelikitchenbyyoly/?modal=admin_todo_tour" className="fa fa-facebook"> </a>
            <a href="https://www.pinterest.com.mx/delidelikitchen/" className="fa fa-pinterest"> </a>
            <a href="https://www.instagram.com/delidelikitchen/?hl=en" className="fa fa-instagram"> </a>
            <a href="https://twitter.com/delidelikitchen" className="fa fa-twitter"> </a>
            </div>
            
            
        </div>
        </div>

        </div>
    </div>


            </div>
            </div>
         );
    }
}
 
export default Footer;