import React, { Component } from 'react';


class Historia extends Component {
    state = {  } 
    render() { 
        return (
<div className="container-md">
    <br></br>
            <div className="container-md d-flex p-0 justify-content-center border-top my-4 p-4">
                <h1 className="texto-titulo">MI HISTORIA</h1>
                
                
            </div>
            <div className="container-lg p-0  fs-5 d-flex p-5 justify-content-center">
                
                <p >Hola! Bienvenidos a mi Blog donde comparto con ustedes mi <strong>pasión y amor</strong> por la <strong>cocina</strong>. Yo soy Yoly y estoy muy contenta de tenerlos por aquí visitándome en mi página o los que me siguen en mi Blog de YouTube me alegra mucho que les guste mi contenido donde comparto con ustedes mi alegría, mi sazón pero sobre todo mi pasión y amor por la cocina!</p>
            </div>
            <br></br>
            <br></br>

    <div className="row">
        <div className="col-lg-4  ">
            <div className="">
            <div className="container-sm  d-flex justify-content-center ">
            <img className="img-historia-yoly" alt="deli logo" src="images/yoly-01.jpg"></img>
            </div>
            <div className="container-sm  d-flex justify-content-center">
                <p className="fs-5 text-center texto-salmon text-uppercase"><strong>"</strong>Soy Mexicana. Apasionada Repostera & Chef Autodidacta y Diseñadora de Interiores de profesión. Ambas unidas por el diseño y el arte. Viví en Alemania por un tiempo y ahí fué donde nació mi pasión por la cocina. Actualmente vivo en Nueva York donde he invertido mi tiempo en el arte culinario preparándome y practicando día a día en éste ámbito que tanto disfruto<strong>"</strong></p>
            </div>
            <br></br>
            </div>
        </div>
        <div className="col-lg-8 d-flex flex-column justify-content-center ">
            <div className="container-md">
            <div className="historia-texto">
                <h3>Alemania, parte de mi</h3>
                <br></br>
                <p>Bueno les cuento un poco sobre mí. En el tiempo que viví en <strong className="texto-salmon">Alemania</strong> descubrí ese <strong className="texto-salmon">amor y pasión por la cocina</strong> que no conocía de mí. Me casé en México y a los 3 meses me fui a vivir a Alemania ya que mi esposo obtuvo una beca y así fué como terminé al otro lado del mundo. Estuvimos viajando mucho mi esposo y yo y no saben lo emocionada que estaba aparte de explorar todos y cada uno de los países europeos y experiencias que nos esperaban, me emocionaba también el probar esos postres y platillos típicos de cada país, desde los croissants y macarons de <strong className="texto-salmon">Francia</strong>, los deliciosos waffles de <strong className="texto-salmon">Bélgica</strong>, las típicas salchichas alemanas con mostaza y sus <strong className="texto-salmon">pasteles</strong> ni les cuento, eran deliciosos!</p>
            </div> 

            <br></br>
            <div className="historia-texto">
                <h3>HABILIDAD POR NECESIDAD</h3>
                <br></br>
                <p>Y literal no fue hasta ese momento que me encontraba <strong className="texto-salmon">fuera de mi país</strong> que me dí cuenta de ésta <strong className="texto-salmon">pasión por cocinar</strong>, me di cuenta que había desarrollado <strong className="texto-salmon">una habilidad por una necesidad</strong>, ya que soy super antojada y extrañaba mil cosas de mi país, ya que no regresamos a <strong className="texto-salmon">Mexico</strong> en 18 meses. Tuve que ingeniármelas para hacer una tortilla de maíz, una tostada, unos tacos o conseguir una salsa verde (que porsupuesto no había tomates verdes en Europa) etc. </p>
                
                <p>Ahí fue el momento donde me dí cuenta que al cocinar se me iba el tiempo, me encantaba estar inventado y creando cosas nuevas, y como soy una persona muy antojada ya no faltaba que me inventaba para lograr comerme ese antojo que tanto quería. </p>
                
                <p>Una de las cosas que me sucedieron y que confirmaron este amor por la cocina, fué un 6 de enero (día de reyes en México), se me antojaba muchísimo una <strong className="texto-salmon">Rosca de Reyes</strong> como no tienen una idea, que por obvias razones ni de chiste iba a encontrarla en Europa, entonces dije: «porque no la hago yo?, no pasa que no me salga» claro que mi conocimiento del pan era nulo como principiante, y bueno fueron alrededor de 7-8 horas para prepararla. Que en lo que se elevaba la masa y en lo que la amasaba y bueno todo el proceso que no tenía ni idea en ese momento acerca del gluten y lo relacionado con el pan. Y que creen, que me salió una deliciosa y esponjosa Rosca de Reyes, literal parecía que había salido de una panadería en México! Yo estaba súper feliz!.</p>
            </div> 

            <br></br>

            <div className="historia-texto">
                <h3>INFLUENCIA FAMILIAR</h3>
                <br></br>
                <p>Definitivamente el amor por la cocina lo tenía y no me había dado cuenta ya que en mi casa mi mamá es la que cocina y su manera de demostrarnos su cariño es preparándonos lo que más nos gusta. Aparte cuando estaba en la universidad no tenía ni el tiempo ni la energía para cocinar, la verdad cocinaba lo más rápido que se podía para poder regresarme al trabajo lo más pronto posible. Por eso entre una cosa y otra <strong className="texto-salmon">nunca supe que a mí también me gustaba la cocina</strong> hasta que tuve el tiempo, los instrumentos y la energía para preparar lo que se me antojara. </p>
                <p>Mi mamá y mi abuelita me han heredado una de las cosas mas valiosas para mí que es su amor y don por la cocina y el estar fuera de mi país es una de las cosas que <strong className="texto-salmon">me conectan y me acompañan a donde quiera que voy</strong>, al cocinar siento que estoy cerca de ellas y no saben lo feliz que me hace honrar a mi familia con este Blog.</p>
            </div> 

            <br></br>

            <div className="historia-texto">
                <h3>NUEVA YORK</h3>
                <br></br>
                <p>Después de Alemania nos mudamos a <strong className="texto-salmon">Nueva York</strong> mi esposo y yo y ya llevamos 4 años viviendo aquí. Y respecto a mi preparación en el ámbito culinario aún lo sigo haciendo ya que estoy tomando cursos en linea en varias áreas como de <strong className="texto-salmon">postres saludables</strong>, otro que me ha ayudado mucho que es <strong className="texto-salmon">la ciencia de la repostería</strong> o cursos que he tomado en Manhattan en el Instituto Culinario de Nueva York (ICE) desde clases de pasta , pizza , comida europea y miles de cursos de <strong className="texto-salmon">repostería</strong> los cuales he disfrutado muchísimo y de los que he aprendido miles de trucos, ingredientes nuevos y técnicas que me encantará compartir con ustedes.</p>
            </div> 


            <br></br>

            <div className="historia-texto">
                <h3>MI FAMILIA Y EL AZÚCAR</h3>
                <br></br>
                <p>Otra parte de mi vida que me gustaría compartirles y que tiene que ver mayormente con que haya creado éste blog es que, después de un tiempo surge un <strong className="texto-salmon">shock muy fuerte en mi familia</strong> porque desde toda nuestra vida hemos sido super antojados y la verdad es que si comíamos demasiadas cosas dulces. Y entre esas cosas cada que viajábamos a EUA nos encantaba comprar de esas bolsa de M&M’s grandes como de medio kilo y nos lo comíamos literal como papas fritas, quiero decir que cada integrante de mi familia nos podíamos comer una bolsa al día cada quien y como en mi familia el comer mucho dulce era normal pues se nos hacía normal comernos ese tipo de cosas.  </p>
                <p>Hasta que un día una de mis hermanas hace poquito <strong className="texto-salmon">en sus 20’s</strong> surgió que padecía <strong className="texto-salmon">DIABETES</strong>, ahí fue donde tristemente abrimos los ojos. Es un padecimiento muy común en todo el mundo pero más en Mexico y EUA por lo que he investigado y ya se nos hace normal, y lo más triste es que nadie toma acción para evitar que padezcas esta enfermedad. Hasta nosotros dijimos “les pasa a otros, a nosotros no nos va a pasar” Y resulta que sí nos pasó. </p>
                <p>No saben lo triste que es para mí el no poder estar cerca de ella para poder hacerle ese postre que tanto se le antoja. Es por eso que también quise hacer este blog para poder ayudarla de manera remota a ella y a todos aquellos que padecen esta enfermedad para que puedan comerse esos postres que tanto se les antojan y poder prepararlos y comerlos libremente. </p>
            </div> 

            <br></br>

            <div className="historia-texto">
                <h3>MI FAMILIA Y EL AZÚCAR</h3>
                <br></br>
                <p>Mi otra hermana esta estudiando <strong className="texto-salmon">NUTRICION & JUNTAS</strong> estamos logrando nuestro objetivo que es ayudar a nuestra hermana y por consecuencia a los <strong className="texto-salmon">diabéticos</strong> y a todas las personas a llevar una vida más saludable pero que puedan seguir disfrutando de ese postre o platillo que tanto se les antoja y que además sepa delicioso.</p>
            </div> 


            



 

            </div>
    






        </div>


    </div>

<div className="container-sm w-75 border-top text-center ">
    <br></br>
<div className="historia-texto ">
                
       
                <p className="fs-5">Así que mi propósito con éste blog es <strong className="texto-salmon">ayudar a cada uno de ustedes</strong> a que lleven una vida más saludable y puedan consumir el azúcar con moderación.</p>

            </div> 
</div>

<div className="container-sm   text-center">
    <br></br>
<div className="historia-texto border-top py-4">
                
<div className="fs-3 fst-italic text-bold">«Siempre la opcion Mas Saludable es paraparlo tu mismo»</div>

          
</div>    

<div className="historia-texto border-top py-4 ">
<div className="fs-5 text-bold text-uppercase">y con <a href="https://www.youtube.com/channel/UCJ7S2DnLRiNj8Vg9XnAPIbA?view_as=subscriber"><strong className="texto-salmon"><u>MIS VIDEOS</u></strong></a> & <strong className="texto-salmon">RECETAS</strong> «PASO A PASO» lo haremos a la par y te enseñare que es MUY FACIL prepararlos!</div> 
</div>

<div className="historia-texto border-top py-4 ">
<div className="fs-5 text-bold text-uppercase">Si quieres recibir recetas cada semana puedes <a href="https://www.youtube.com/channel/UCJ7S2DnLRiNj8Vg9XnAPIbA?view_as=subscriber"><strong className="texto-salmon"><u>SUSCRIBIRTE</u></strong></a> a mi canal de YouTube completamente GRATIS!</div> 
<div className="fs-5 text-bold text-uppercase py-4">Click <a href="https://www.youtube.com/channel/UCJ7S2DnLRiNj8Vg9XnAPIbA?view_as=subscriber"><strong className="texto-salmon"><u>Aqui</u></strong></a> para Suscribirte!</div> 
</div>



</div>
</div>
        );
    }
}
 
export default Historia;