import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Nav extends Component {
    state = {  } 
    render() { 
        return (
<div className="sticky-top">
<nav className="navbar navbar-expand-sm  navbar-light bg-white ">
  <div className="container-fluid">
    <a className="navbar-brand" href="/">
      <img alt="deli logo" width="100px"src="images/logo-deledelikitchen1.png"></img></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span className="navbar-toggler-icon"></span>
    </button>
    {/* Menu */}
    <div className="collapse navbar-collapse fw-bolder " id="mynavbar">
      <ul className="navbar-nav me-auto">
        <li className="nav-item">
        <Link className="nav-link fs-5" to="/">HOME</Link>
        </li>
{/*
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            RECETAS
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="#">POSTRES MENOS AZUCAR</a></li>
            <li><a className="dropdown-item" href="#">POSTRES SALUDABLES</a></li>
            <li><a className="dropdown-item" href="#">POSTRES SIN AZUCAR</a></li>
            <li><a className="dropdown-item" href="/receta">RECETAS CASERAS</a></li>
          </ul>
        </li>
        */}

    
        <li className="nav-item">
        <Link className="nav-link fs-5" to="/historia">MI HISTORIA</Link>
         
        </li>
        <li className="nav-item">
        <Link className="nav-link fs-5" to="/letsconnect">LETS CONNECT</Link>
          
        </li>
      </ul>
  
    </div>
  
  </div>
</nav>


</div>

        );
    }
}
 
export default Nav;