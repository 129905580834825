import React, { Component } from 'react';
import recetas from './recetas.json'
import {Link} from 'react-router-dom';


class Home extends Component {
    state = {
      
      } 
      
    render() { 
        
        
        
     
        return (
            <div className="container-fluid p-0">
          
                {/* 
                <div className="container-fluid position-relative hero-image ">
              
                  <div>  <img className="hero-image-img" src="images/1-cheesecake-ddk2.jpg"></img></div>
                 
                  <div className=" container-fluid bottom-0  position-absolute  hero-gradient">
                      <div className="container-fluid m-0 py-5 text-white text-center bottom-0 position-absolute  ">
                          <div className="container-md d-flex justify-content-center">
                              <div className="marco-50 ">
                          <h2 className="titulo-texto py-5">Cheescake saludable</h2>
                          <p className="fs-6">Aquí te dejo MI RECETA DE CHEESECAKE SALUDABLE DE VAINILLA!

Este cheesecake saludable de vainilla lo preparé para festejar con ustedes este 14 de febrero! es una opción más saludable para tenerlo como opción de un postre ligero el cual si consumes una rebanada todos los días no sentirás que estás comiendo un postre muy dulce o muy pesado.</p>
</div>
                          </div>
                          

                      </div>
                  </div>
                </div>*/}
               
               
               {/*info */}
<div className="container-fluid bg-light p-0">


<div className="container-lg position-relative   p-0 ">


        <div className="container-fluid d-flex justify-content-center imagen-portada shadow p-0 m-0">
        
     
        <img className="imagen-portada shadow" src="images/portada-ddk1.jpg"></img>
        <br></br>
        <br></br>
        </div>
        <div className="position-absolute posicion-logo borde">
        <img className="img-portada-yoly shadow" alt="deli logo" src="images/yoly-01.jpg"></img>
        </div>

</div>
<br></br>

<div className="container-lg ">
    <br></br>
    <br></br>

    <div className="row">
        <div className="col-sm-2  ">
            <br></br>
            <br></br>
            <h1 className="text-bold ">RECETAS FACILES Y DELICIOSAS</h1>
            <br></br>
           
           
        </div>

        <div className="col-sm-10">
            <div className="container-fluid d-flex flex-wrap justify-content-center  content-align-center">
                 {recetas.map((una)=>(
                    <Link key={una.id} className="link-clean" to={`/receta/${una.titulo_url}`}><div  className="position-relative home-receta-cuadro ">
                        <div className="position-absolute  p-0 m-0 marco"></div>
                       
                        <div className="container-lg  p-0 m-2 d-flex flex-column  justify-content-center">
                            <div><img className=" home-imagen-receta  " alt ={una.titulo} src={una.imagen}></img></div>
                            
                            <div><h4 className=" container-fluid  px-0 fs-4 py-2   fw-bold  text-center  ">{una.titulo}</h4></div>

                        </div>
                    </div></Link>
                ) )}

            </div>
            
        </div>

    </div>

</div> 
</div>
            

            
            
            
            </div>


        );
    }
}
 
export default Home;


{/*
{
    "id": "19",
    "titulo": "Cupcakes de Unicornio",
    "categoria":"Cupcake",
    "tags":["cupcake","unicornio"],
    "titulo_url":"cupcake-de-unicornio",
    "imagen":"../images/recetas/cupcake-de-unicornio.jpg",
    "ytlink":"-QmztHzpnE8"
},

*/}