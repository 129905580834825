import React, { Component } from 'react';
import recetas from './recetas.json'
import {Link} from 'react-router-dom';


class Tags extends Component {
    state = {
        input:{tipo:"tags"},
        busqueda:[],
      } 

componentDidUpdate( prevProps, prevState){if (prevProps.location.pathname !== this.props.location.pathname){this.pullinfo()}}

componentDidMount(){this.pullinfo()}
    
    pullinfo = () =>{
        let array=[]
        let api_nombre = this.props.location.pathname.slice(this.state.input.tipo.length+2)
        console.log(api_nombre)

        for (let i = 0; i < recetas.length; i++) {
            for (let t = 0; t < recetas[i].tags.length; t++) {
                if(recetas[i].tags[t]===api_nombre){
                   array.push(recetas[i]) 
                }
            }   
        }
console.log(array)
this.setState({busqueda:array})
    } 

render() { 
        
        
        
     
        return (
            <div className="container-fluid p-0">
   
<div className="container-fluid bg-light p-0">


<br></br>

<div className="container-lg ">
    <br></br>
    <br></br>

    <div className="row">
        <div className="col-sm-2  ">
            <br></br>
            <br></br>
            <h1 className="text-bold ">RESULTADOS BUSQUEDA</h1>
            <br></br>
           
           
        </div>

        <div className="col-sm-10">
            <div className="container-fluid d-flex flex-wrap justify-content-center  content-align-center">
                 {this.state.busqueda.map((una)=>(
                    <Link key={una.id} className="link-clean" to={`/receta/${una.titulo_url}`}><div className="position-relative home-receta-cuadro ">
                        <div className="position-absolute  p-0 m-0 marco"></div>
                       
                        <div className="container-lg  p-0 m-2 d-flex flex-column  justify-content-center">
                            <div><img alt={una.titulo} className=" home-imagen-receta  " src={una.imagen}></img></div>
                            
                            <div><h4 className=" container-fluid  px-0 fs-4 py-2   fw-bold  text-center  ">{una.titulo}</h4></div>

                        </div>
                    </div></Link>
                ) )}

            </div>
            
        </div>

    </div>

</div> 
</div>
            
           
            
            
            
            
            </div>


        );
    }
}
 
export default Tags;