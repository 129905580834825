import React, { Component } from 'react';


import recetas from './recetas.json'




class Receta extends Component {
    state = {
        /*valores*/
          input:{tipo:"receta"},
          info:{},
          
        }
    
componentDidUpdate( prevProps, prevState){if (prevProps.location.pathname !== this.props.location.pathname){this.pullinfo()}}

componentDidMount(){this.pullinfo()}
    
    pullinfo = () =>{
            let api_nombre = this.props.location.pathname.slice(this.state.input.tipo.length+2)
            
            console.log(api_nombre)
        

            var __FOUND = recetas.findIndex(function(post, index) {
                if(post.titulo_url === api_nombre)
                    return true;
            });   
            this.setState({info:recetas[__FOUND]})
            
          } 
           
        render() { 
            console.log(this.state.info.tags)
            
        return (
<div className="container-md">
    <div className="row ">
        <div className="col-lg-5 ">
            <div className="d-flex justify-content-center shadow">
            <img alt="" className="imagen-lateral " src={this.state.info.imagen}></img>
            </div>
            
        </div>
        <div className="col-lg-7">
            <div className="d-flex flex-column text-center justify-content-center">
            <h1 className="">{this.state.info.titulo}</h1>
            <div className="fs-4 text-uppercase">{this.state.info.categoria}</div>

            
            <div className="container-lg d-flex justify-content-center ">
            <iframe className="iframe-yt " src={`https://www.youtube.com/embed/${this.state.info.ytlink}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
           
            </div>
   
        </div>
        
    </div>
    


                
</div>
        );
    }
}
 
export default Receta;