import React, { Component } from 'react';

class Connect extends Component {
    state = {  } 
    render() { 
        return (
    <div>

       
        <div className="container-sm d-flex flex-column align-items-center">
            <div className=""><h1>Let's Connect!</h1></div>
            <div className="py-3"><a href="https://www.youtube.com/channel/UCJ7S2DnLRiNj8Vg9XnAPIbA"><img alt="connect youtube" className="imagen-connect"src="../images/logos/1-yt4.jpg"></img></a></div>
            <div className="py-3"><a href="https://www.pinterest.es/delidelikitchen/_created/"><img alt="connect pinterest" className="imagen-connect" src="../images/logos/4-pint.jpg"></img></a></div>
            <div className="py-3"><a href="https://twitter.com/delidelikitchen"><img className="imagen-connect" alt="connect twitter" src="../images/logos/3-twit.jpg"></img></a></div>
        </div>
    </div>
        );
    }
}
 
export default Connect;